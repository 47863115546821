import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, GeckoTerminalIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://docs.smbswap.finance/smbswap/contact-us",
      },
      {
        label: "Blog",
        href: "https://blog.smbswap.finance/",
      },
      {
        label: "Community",
        href: "https://docs.smbswap.finance/smbswap/contact-us/telegram",
      },
      {
        label: "SELF",
        href: "https://docs.smbswap.finance/smbswap/tokenomics/self",
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.smbswap.finance/smbswap/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.smbswap.finance/smbswap/help-area",
      },
      {
        label: "Guides",
        href: "https://docs.smbswap.finance/smbswap/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/smbswap",
      },
      {
        label: "Documentation",
        href: "https://docs.smbswap.finance/smbswap",
      },
      // {
      //   label: "Bug Bounty",
      //   href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
      // },
      // {
      //   label: "Audits",
      //   href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
      // },
      // {
      //   label: "Careers",
      //   href: "https://docs.pancakeswap.finance/hiring/become-a-chef",
      // },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/smbswap",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    items: [
      {
        label: "English",
        href: "https://t.me/SMBSwap",
      },
      {
        label: "Announcements",
        href: "https://t.me/SMBSwapAnncouncements",
      },
    ],
  },
  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com/r/smbswap",
  },
  {
    label: "Instagram",
    icon: InstagramIcon,
    href: "https://instagram.com/smbswap_official",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/cr3k/",
  },
  {
    label: "Discord",
    icon: DiscordIcon,
    href: "https://discord.gg/smbswap",
  },
  {
    label: "GeckoTerminal",
    icon: GeckoTerminalIcon,
    href: "https://www.geckoterminal.com/bsc/pools/0x8134591eb77ab0a6f62b1c3fb24aa9bcf8556876",
  },  
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
