import { bscTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SELF_BNB_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: bscTokens.self,
    token1: bscTokens.wbnb,
    lpAddress: '0x147749bB5423D201F982b5bDe39161D291387c39',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 2,
    token0: bscTokens.self,
    token1: bscTokens.busd,
    lpAddress: '0x8A3658a886261F2AF93Fb1B9d795C932176A53Ca',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 3,
    token0: bscTokens.usdt,
    token1: bscTokens.self,
    lpAddress: '0xD0fB9fEE1e04AD1467859c96974471F63d4BF73d',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 4,
    token0: bscTokens.wbnb,
    token1: bscTokens.busd,
    lpAddress: '0xA258Ce6609Fc033EA5186516531aD7029d0e1696',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 5,
    token0: bscTokens.usdt,
    token1: bscTokens.wbnb,
    lpAddress: '0x86933cf502Cd62a989197B5f150fa398CD561ecE',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 7,
    token0: bscTokens.rants,
    token1: bscTokens.wbnb,
    lpAddress: '0x4D40efac2d68e7d0B268E6eCB11099E2D63d5952',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 8,
    token0: bscTokens.usdt,
    token1: bscTokens.rants,
    lpAddress: '0xbffE156f427BBB1e4Ee86c02A2008069ca96dEac',
    feeAmount: FeeAmount.HIGH,
  },    
  // {
  //   pid: 6,
  //   token0: bscTokens.usdt,
  //   token1: bscTokens.brnkc,
  //   lpAddress: '0xDD1e71B8800c3C684CFb7aE0536B8d959077A186',
  //   feeAmount: FeeAmount.HIGH,
  // },  
  // {
  //   pid: 6,
  //   token0: bscTokens.btcb,
  //   token1: bscTokens.busd,
  //   lpAddress: '0x369482C78baD380a036cAB827fE677C1903d1523',
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 7,
  //   token0: bscTokens.usdt,
  //   token1: bscTokens.btcb,
  //   lpAddress: '0x46Cf1cF8c69595804ba91dFdd8d6b960c9B0a7C4',
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 8,
  //   token0: bscTokens.eth,
  //   token1: bscTokens.btcb,
  //   lpAddress: '0xD4dCA84E1808da3354924cD243c66828cf775470',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 9,
  //   token0: bscTokens.btcb,
  //   token1: bscTokens.wbnb,
  //   lpAddress: '0xFC75f4E78bf71eD5066dB9ca771D4CcB7C1264E0',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 10,
  //   token0: bscTokens.eth,
  //   token1: bscTokens.wbnb,
  //   lpAddress: '0x7d05c84581f0C41AD80ddf677A510360bae09a5A',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 11,
  //   token0: bscTokens.eth,
  //   token1: bscTokens.usdc,
  //   lpAddress: '0x539e0EBfffd39e54A0f7E5F8FEc40ade7933A664',
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 12,
  //   token0: bscTokens.usdt,
  //   token1: bscTokens.usdc,
  //   lpAddress: '0x92b7807bF19b7DDdf89b706143896d05228f3121',
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 13,
  //   token0: bscTokens.usdc,
  //   token1: bscTokens.busd,
  //   lpAddress: '0x22536030B9cE783B6Ddfb9a39ac7F439f568E5e6',
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 14,
  //   token0: bscTokens.usdt,
  //   token1: bscTokens.busd,
  //   lpAddress: '0x4f3126d5DE26413AbDCF6948943FB9D0847d9818',
  //   feeAmount: FeeAmount.LOWEST,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'SELF',
    lpAddress: '0x7a364484303B38Bce7B0ab60a20DA8F2F4370129',
    token: bscTokens.made,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 2,
    v1pid: 2,
    lpSymbol: 'SELF-BNB LP',
    lpAddress: SELF_BNB_LP_MAINNET,
    token: bscTokens.self,
    quoteToken: bscTokens.wbnb,
    boosted: true,
  },
  {
    pid: 3,
    v1pid: 1,
    lpSymbol: 'BUSD-BNB LP',
    lpAddress: '0xFD138eB9Eb01415090DBB36d29C7169193384a6F',
    token: bscTokens.busd,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 32,
    v1pid: 3,
    lpSymbol: 'SELF-BUSD LP',
    lpAddress: '0xaaf2685565C898d1b853aB5d6D1405854890a8dD',
    boosted: true,
    token: bscTokens.self,
    quoteToken: bscTokens.busd,
  },

  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  // {
  //   pid: 135,
  //   lpSymbol: 'USDT-USDC LP',
  //   lpAddress: '0xee1bcc9F1692E81A281b3a302a4b67890BA4be76',
  //   token: bscTokens.usdt,
  //   quoteToken: bscTokens.usdc,
  //   stableSwapAddress: '0x3EFebC418efB585248A0D2140cfb87aFcc2C63DD',
  //   infoStableSwapAddress: '0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6',
  //   stableLpFee: 0.00005,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  // {
  //   pid: 134,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddress: '0x1A77C359D0019cD8F4d36b7CDf5a88043D801072',
  //   token: bscTokens.usdc,
  //   quoteToken: bscTokens.busd,
  //   stableSwapAddress: '0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe',
  //   infoStableSwapAddress: '0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6',
  //   stableLpFee: 0.00005,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },


  // {
  //   pid: 36,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddress: '0xC7802bffd5c87C9e6d6687D6183E2b06BEE1A8A0',  // ours
  //   token: bscTokens.usdc,
  //   quoteToken: bscTokens.busd,
  //   stableSwapAddress: '0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe',
  //   infoStableSwapAddress: '0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6',
  //   stableLpFee: 0.00005,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  // {
  //   pid: 35,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddress: '0x08F742B4EA2afA84c150E591fB27aabB236dC04F',  // ours
  //   token: bscTokens.usdt,
  //   quoteToken: bscTokens.busd,
  //   stableSwapAddress: '0x169F653A54ACD441aB34B73dA9946e2C451787EF',
  //   infoStableSwapAddress: '0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6',
  //   stableLpFee: 0.00005,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  // {
  //   pid: 121,
  //   lpSymbol: 'HAY-BUSD LP',
  //   lpAddress: '0xB6040A9F294477dDAdf5543a24E5463B8F2423Ae',
  //   token: bscTokens.hay,
  //   quoteToken: bscTokens.busd,
  //   stableSwapAddress: '0x49079d07ef47449af808a4f36c2a8dec975594ec',
  //   infoStableSwapAddress: '0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
  {
    pid: 34,
    lpSymbol: 'WSAFE-BNB LP',
    lpAddress: '0x66167E113e6d415942608542c24dE4D85eB241B1',
    token: bscTokens.wsafe,
    quoteToken: bscTokens.wbnb,  
  },  
  {
    pid: 31,
    v1pid: 54,
    lpSymbol: 'RMB-SELF LP',
    lpAddress: '0xd2B5Aa90d2D50123e002a547889488Ef11CF3C39',
    token: bscTokens.rmb,
    quoteToken: bscTokens.self,  
  },
  {
    pid: 30,
    v1pid: 49,
    lpSymbol: 'WPGO-BNB LP',
    lpAddress: '0xABf1115130A48060D7E06775dB14aBb09053328B',
    token: bscTokens.wpgo,
    quoteToken: bscTokens.wbnb,  
  },
  {
    pid: 29,
    v1pid: 51,
    lpSymbol: 'USDE-BNB LP',
    lpAddress: '0x34B292504ab97B4aeA63882dBb3aCC2d3a9a9B67',
    token: bscTokens.usde,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 28,
    v1pid: 46,
    lpSymbol: 'WBLK-BNB LP',
    lpAddress: '0xE7eF6A5E0aa1Ed32f78fb90DC864a1dC4f35f92D',
    token: bscTokens.wblk,
    quoteToken: bscTokens.wbnb,    
  },
  {
    pid: 27,
    v1pid: 48,
    lpSymbol: 'TLS-SELF LP',
    lpAddress: '0x92558656B5E28309D3E59E600F55208d059faCe9',
    token: bscTokens.tls,
    quoteToken: bscTokens.self,
  },
  {
    pid: 26,
    v1pid: 47,
    lpSymbol: 'TLS-BNB LP',
    lpAddress: '0x7521a1599082c3184ecB7Bf2Cc8d8F39949447AB',
    token: bscTokens.tls,
    quoteToken: bscTokens.wbnb,
  },    
  {
    pid: 25,
    v1pid: 45,
    lpSymbol: 'WANTS-RANTS LP',
    lpAddress: '0x1d6ab9C25592A82F9f09b1E145C9784Ddf75b482',
    token: bscTokens.wants,
    quoteToken: bscTokens.rants,    
  },
  {
    pid: 24,
    v1pid: 44,
    lpSymbol: 'RANTS-BUSD LP',
    lpAddress: '0x6Bb9b76CfCa09AC7b06F08831a6CbC4080cc1046',
    token: bscTokens.rants,
    quoteToken: bscTokens.busd,
  },
  {
    pid: 23,
    v1pid: 43,
    lpSymbol: 'WANTS-BUSD LP',
    lpAddress: '0x83976492Bd5CCd53a4f6478cF159201A5E59ED5B',
    token: bscTokens.wants,
    quoteToken: bscTokens.busd,
  },
  {
    pid: 22,
    v1pid: 42,
    lpSymbol: 'WANTS-BNB LP',
    lpAddress: '0xfA45788ac5E10c777d30c2Dc191b4Bc6cCe75898',
    token: bscTokens.wants,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 21,
    v1pid: 41,
    lpSymbol: 'TATER-BNB LP',
    lpAddress: '0x8058aD403929C564f2b2543ddD0695B7187D9787',
    token: bscTokens.tater,
    quoteToken: bscTokens.wbnb,    
  },
  {
    pid: 20,
    v1pid: 40,
    lpSymbol: 'TATER-SELF LP',
    lpAddress: '0x834f43FA98D10eA4Fcdd6C4A725F2C91974E3599',
    token: bscTokens.tater,
    quoteToken: bscTokens.self,
  },  
  {
    pid: 19,
    v1pid: 39,
    lpSymbol: 'BTR3B-BNB LP',
    lpAddress: '0xC51a603afe96B178b343b6F42E7d5Cabeb407bfc',
    token: bscTokens.btr3b,
    quoteToken: bscTokens.wbnb,
  }, 
  {
    pid: 18,
    v1pid: 38,
    lpSymbol: 'CROCFI-BNB LP',
    lpAddress: '0x39192bf329bee568025e0adcc6860d3b708c2b7c',
    token: bscTokens.crocfi,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 17,
    v1pid: 37,
    lpSymbol: 'CROCFI-BUSD LP',
    lpAddress: '0x6909e132Dae38595EdA7C5e9B172c75c58b1e47f',
    token: bscTokens.crocfi,
    quoteToken: bscTokens.busd,
  },
  {
    pid: 16,
    v1pid: 36,
    lpSymbol: 'CROCFI-SELF LP',
    lpAddress: '0x592Bf16E2F23eDBE99314712Cb81f7363D6E03Cf',
    token: bscTokens.crocfi,
    quoteToken: bscTokens.self,
  },
  {
    pid: 15,
    v1pid: 34,
    lpSymbol: 'BTR3B-SELF LP',
    lpAddress: '0xF380d77FfB3CdC9bC0DbfE7B3DA6ec6F4b6bc4C9',
    token: bscTokens.btr3b,
    quoteToken: bscTokens.self,
  }, 
  {
    pid: 14,
    v1pid: 32,
    lpSymbol: 'BTCE-SELF LP',
    lpAddress: '0xf0d305C0D05B0C627012588800b4f9b32C197007',
    token: bscTokens.btce,
    quoteToken: bscTokens.self,
  },  
  {
    pid: 13,
    v1pid: 29,
    lpSymbol: 'ASX-BNB LP',
    lpAddress: '0xbe5560a891636F9B24CAE2E8C34E79f8826a2AE6',
    token: bscTokens.asx,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 12,
    v1pid: 28,
    lpSymbol: 'SMBR-BNB LP',
    lpAddress: '0xEF6767F7c86E5c52FfE25639f3689A5FC21733E4',
    token: bscTokens.smbr,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 11,
    v1pid: 25,
    lpSymbol: 'BTCE-BNB LP',
    lpAddress: '0xFaA01D48b5e990F2aec97329b05d7080a4978bC1',
    token: bscTokens.btce,
    quoteToken: bscTokens.wbnb,
  },  
   {
    pid: 10,
    v1pid: 15,
    lpSymbol: 'rANTS-SELF LP',
    lpAddress: '0x3d66f7714677b619e3fb74399676E898f9535cae',
    token: bscTokens.rants,
    quoteToken: bscTokens.self,
  },
  {
    pid: 9,
    v1pid: 14,
    lpSymbol: 'rANTS-BNB LP',
    lpAddress: '0x11eC0bB66c91c0b53f4B0fA88c93918A8B76f60f',
    token: bscTokens.rants,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 8,
    v1pid: 8,
    lpSymbol: 'USDT-BNB LP',
    lpAddress: '0x0A7D9A057340261F6Ab6b757CA67f7b8Ed14a8da',
    token: bscTokens.usdt,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 7,
    v1pid: 7,
    lpSymbol: 'SMBElon-BNB LP',
    lpAddress: '0xc2d9f5C184226294940Be1CeC0599dF38Df1F2A8',
    token: bscTokens.smbelon,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'SMB-BNB LP',
    lpAddress: '0xA6d003C91F654c916393634f74a4c31283eF70BC',
    token: bscTokens.smb,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'SMBGE-BNB LP',
    lpAddress: '0x4fa397c8785427c8C963cFeCB94aa4ff50Edca00',
    token: bscTokens.smbge,
    quoteToken: bscTokens.wbnb,
  },
  // {
  //   pid: 4,
  //   v1pid: 4,
  //   lpSymbol: 'ETH-BNB LP',
  //   lpAddress: '0x307C2B1c3F8C28cc50c0A9DBc300C8d10B0Ce2D4',
  //   token: bscTokens.eth,
  //   quoteToken: bscTokens.wbnb,
  // },  
  // {
  //   pid: 4,
  //   v1pid: 3,
  //   lpSymbol: 'SELF-BUSD LP',
  //   lpAddress: '0xaaf2685565C898d1b853aB5d6D1405854890a8dD',
  //   token: bscTokens.self,
  //   quoteToken: bscTokens.busd,
  // },

  // {
  //   pid: 120,
  //   lpSymbol: 'HOOP-BUSD LP',
  //   lpAddress: '0xdE8a4f457af12F98DB090841579Feed384684819',
  //   token: bscTokens.hoop,
  //   quoteToken: bscTokens.busd,
  // },
  // {
  //   pid: 106,
  //   lpSymbol: 'MHUNT-BNB LP',
  //   lpAddress: '0x58aED290F42963A502626774Bd8fa03f33c9B71f',
  //   token: bscTokens.mhunt,
  //   quoteToken: bscTokens.wbnb,
  //   isCommunity: true,
  //   auctionHostingStartSeconds: 1663315200,
  // },
  // {
  //   pid: 73,
  //   v1pid: 491,
  //   lpSymbol: 'HIGH-BUSD LP',
  //   lpAddress: '0xe98ac95A1dB2fCaaa9c7D4ba7ecfCE4877ca2bEa',
  //   token: bscTokens.high,
  //   quoteToken: bscTokens.busd,
  // },
  // {
  //   pid: 4,
  //   v1pid: 253,
  //   lpSymbol: 'ADA-BNB LP',
  //   lpAddress: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
  //   token: bscTokens.ada,
  //   quoteToken: bscTokens.wbnb,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
