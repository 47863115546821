import { ChainId } from '@pancakeswap/sdk'

import { SELF_FLEXIBLE_SIDE_VAULT, SELF_VAULT } from '../constants/contracts'
import { getContractAddress } from '../utils'

export function getSelfFlexibleSideVaultAddress(chainId: ChainId) {
  return getContractAddress(SELF_FLEXIBLE_SIDE_VAULT, chainId)
}

export function getSelfVaultAddress(chainId: ChainId) {
  return getContractAddress(SELF_VAULT, chainId)
}