import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("About"),
    items: [
      {
        label: t('Contact'),
        href: 'https://docs.smbswap.finance/smbswap/contact-us',
        isHighlighted: true,
      },
      {
        label: t('Brand'),
        href: 'https://docs.smbswap.finance/smbswap/brand',
      },
      {
        label: t('Blog'),
        href: "https://blog.smbswap.finance/",
      },
      {
        label: t('Community'),
        href: 'https://docs.smbswap.finance/smbswap/contact-us/community',
      },
      // {
      //   label: t('Litepaper'),
      //   href: 'https://v2litepaper.smbswap.finance/',
      // },
      // {
      //   label: t("SELF Emission Project"),
      //   href: "https://analytics.smbswap.finance/",
      // },      
      {
        label: t("Terms Of Service"),
        href: "https://smbswap.finance/terms-of-service",
      },      
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://docs.smbswap.finance/smbswap/contact-us/customer-support',
      },
      {
        label: t('Troubleshooting'),
        href: 'https://docs.smbswap.finance/smbswap/help-area',
      },
      {
        label: t('Guides'),
        href: 'https://docs.smbswap.finance/smbswap/get-started',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/Cr3k/',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.smbswap.finance/smbswap',
      },
      {
        label: t('Report A Bug'),
        href: 'https://docs.smbswap.finance/smbswap/developers/report-a-bug',
      },
      // {
      //   label: t('Audits'),
      //   href: 'https://docs.smbswap.finance/smbswap/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
      // },
      {
        label: t('Careers'),
        href: 'https://docs.smbswap.finance/smbswap/join-the-team',
      },
    ],
  },
];
