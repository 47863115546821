import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const ISELF = {
  [ChainId.BSC]: '0x33339a4b2e81286afcbe91f12ecc7c290e08e4db', // ours
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>

export const SELF_VAULT = {
  [ChainId.BSC]: '0x54eff9d9539206514fbc2e5b00fbe4168fad4aa0', // ours
  [ChainId.BSC_TESTNET]: '0x2c8CC26b700e87B068ef06EF618125eE1b287b98', // ours
  [ChainId.ETHEREUM]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>

export const SELF_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x61aed69b75a935c9ae58febb9ff7c151b159997e',  // ours
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>