import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import {
  bscTestnetTokens,
  bscTokens,
  ethereumTokens,
  goerliTestnetTokens,
  zkSyncTestnetTokens,
  polygonZkEvmTokens,
  brnkcTokens,
} from '@pancakeswap/tokens'
import type { CommonPrice } from '../../src/fetchFarmsV3'
import type { FarmV3SupportedChainId } from '../../src'

export const SELF_BNB_LP_MAINNET = '0x9C6FF656A563Ec9057460D8a400E2AC7c2AE0a1C'

export type PriceHelper = {
  chain: string
  list: ERC20Token[]
}

export const CHAIN_ID_TO_CHAIN_NAME = {
  [ChainId.BSC]: 'bsc',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.GOERLI]: '',
  [ChainId.BSC_TESTNET]: '',
  [ChainId.POLYGON_ZKEVM]: 'polygon_zkevm',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '',
  [ChainId.ZKSYNC_TESTNET]: '',
  [ChainId.BRNKC]: 'brnkc',
  [ChainId.BRNKC_TESTNET]: '',
} satisfies Record<FarmV3SupportedChainId, string>

export const priceHelperTokens = {
  [ChainId.ETHEREUM]: {
    chain: 'ethereum',
    list: [ethereumTokens.weth, ethereumTokens.usdc, ethereumTokens.usdt],
  },
  [ChainId.BSC]: {
    chain: 'bsc',
    list: [bscTokens.wbnb, bscTokens.usdt, bscTokens.busd, bscTokens.eth],
  },
  [ChainId.POLYGON_ZKEVM]: {
    chain: 'polygon_zkevm',
    list: [polygonZkEvmTokens.weth, polygonZkEvmTokens.usdc, polygonZkEvmTokens.usdt, polygonZkEvmTokens.matic],
  },
  [ChainId.BRNKC]: {
    chain: 'brnkc',
    list: [brnkcTokens.weth, brnkcTokens.usdc, brnkcTokens.usdt, brnkcTokens.brnkc],
  },    
} satisfies Record<number, PriceHelper>

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<FarmV3SupportedChainId, CommonPrice> = {
  [ChainId.ETHEREUM]: {},
  [ChainId.GOERLI]: {},
//    [goerliTestnetTokens.mockA.address]: '10',
//  },
  [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {},
//    [bscTestnetTokens.mockA.address]: '10',
  //   [bscTestnetTokens.usdt.address]: '1',
  //   [bscTestnetTokens.busd.address]: '1',
  //   [bscTestnetTokens.usdc.address]: '1',
  // },
  [ChainId.ZKSYNC_TESTNET]: {
    [zkSyncTestnetTokens.mock.address]: '10',
  },
  [ChainId.POLYGON_ZKEVM]: {},  
  [ChainId.POLYGON_ZKEVM_TESTNET]: {},
  [ChainId.BRNKC]: {},
  [ChainId.BRNKC_TESTNET]: {},  
}