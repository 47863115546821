export const affiliateProgramABI = [
    {
      inputs: [
        {
          internalType: 'address',
          name: '_admin',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_signer',
          type: 'address',
        },
        {
          internalType: 'address',
          name: '_SELF',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: '_dailyLimit',
          type: 'uint256',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'nonce',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'claimer',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'enum AffiliateProgram.ClaimType',
          name: 'claimType',
          type: 'uint8',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
      ],
      name: 'Claim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'timestamp',
          type: 'uint256',
        },
      ],
      name: 'ForceResetLimit',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'enum AffiliateProgram.ClaimType',
          name: 'claimType',
          type: 'uint8',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'nonce',
          type: 'uint256',
        },
      ],
      name: 'IncrementNonce',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Paused',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'dailyLimit',
          type: 'uint256',
        },
      ],
      name: 'SetDailyLimit',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'signer',
          type: 'address',
        },
      ],
      name: 'SetSigner',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'account',
          type: 'address',
        },
      ],
      name: 'Unpaused',
      type: 'event',
    },
    {
      inputs: [],
      name: 'approvalSigner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'claimer',
              type: 'address',
            },
            {
              internalType: 'enum AffiliateProgram.ClaimType',
              name: 'claimType',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'totalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'expiryTimestamp',
              type: 'uint256',
            },
          ],
          internalType: 'struct AffiliateProgram.ClaimRequest',
          name: '_request',
          type: 'tuple',
        },
        {
          internalType: 'bytes',
          name: '_signature',
          type: 'bytes',
        },
      ],
      name: 'claim',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'claimer',
              type: 'address',
            },
            {
              internalType: 'enum AffiliateProgram.ClaimType',
              name: 'claimType',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'totalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'expiryTimestamp',
              type: 'uint256',
            },
          ],
          internalType: 'struct AffiliateProgram.ClaimRequest[]',
          name: '_request',
          type: 'tuple[]',
        },
        {
          internalType: 'bytes[]',
          name: '_signature',
          type: 'bytes[]',
        },
      ],
      name: 'claimMulti',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'dailyClaimLimit',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'dailyClaimedAmount',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'forceResetDailyLimit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
      ],
      name: 'getAffiliateInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalClaimedAmount',
              type: 'uint256',
            },
          ],
          internalType: 'struct AffiliateProgram.UserInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
      ],
      name: 'getUserInfo',
      outputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'totalClaimedAmount',
              type: 'uint256',
            },
          ],
          internalType: 'struct AffiliateProgram.UserInfo',
          name: '',
          type: 'tuple',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_user',
          type: 'address',
        },
        {
          internalType: 'enum AffiliateProgram.ClaimType',
          name: '_claimType',
          type: 'uint8',
        },
      ],
      name: 'incrementNonce',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'lastLimitResetTimestamp',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'pauseClaim',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'paused',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_dailyLimit',
          type: 'uint256',
        },
      ],
      name: 'setDailyLimit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_signer',
          type: 'address',
        },
      ],
      name: 'setSigner',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          components: [
            {
              internalType: 'uint256',
              name: 'nonce',
              type: 'uint256',
            },
            {
              internalType: 'address',
              name: 'claimer',
              type: 'address',
            },
            {
              internalType: 'enum AffiliateProgram.ClaimType',
              name: 'claimType',
              type: 'uint8',
            },
            {
              internalType: 'uint256',
              name: 'totalAmount',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'expiryTimestamp',
              type: 'uint256',
            },
          ],
          internalType: 'struct AffiliateProgram.ClaimRequest',
          name: '_request',
          type: 'tuple',
        },
        {
          internalType: 'bytes',
          name: '_signature',
          type: 'bytes',
        },
      ],
      name: 'verifySignature',
      outputs: [
        {
          internalType: 'bool',
          name: '',
          type: 'bool',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_token',
          type: 'address',
        },
      ],
      name: 'withdraw',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ] as const