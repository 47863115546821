import { ERC20Token, ChainId } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'
import { SELF_BNB_LP_MAINNET } from './lp'
import { Ifo } from './types'

export const selfBnbLpToken = new ERC20Token(ChainId.BSC, SELF_BNB_LP_MAINNET, 18, 'SELF-BNB LP')

const ifos: Ifo[] = [
  {
    id: 'mgp',
    address: '0x3fe5a79d4264fe65a1a9e3938286428a0e2e214d',
    isActive: false,
    name: 'MGP',
    plannedStartTime: 1666872000,
    poolBasic: {
      raiseAmount: '$437,500',
    },
    poolUnlimited: {
      raiseAmount: '$1,312,500',
    },
    currency: bscTokens.self,
    token: bscTokens.mgp,
    campaignId: '511900000',
    articleUrl:
      'https://pancakeswap.finance/voting/proposal/0x5bbaee587df5b7e9b52e2b35f35f30afa08e15ab7098c73cb2503319c8d8ce44',
    tokenOfferingPrice: 0.025,
    version: 3.2,
    twitterUrl: 'https://twitter.com/magpiexyz_io',
    description:
      'Magpie is a customized fork of Convex that helps users in the Wombat ecosystem to obtain better yields and voting power',
    vestingTitle:
      'Earn $MGP by converting $WOM and providing liquidities; Lock $MGP to share revenue and receive bribes.',
  },
  {
    id: 'wmx',
    address: '0xC202eb5f2487DA3675c4aAa2C3D2c071078699c1',
    isActive: false,
    name: 'WMX',
    poolBasic: {
      raiseAmount: '$300,000',
    },
    poolUnlimited: {
      raiseAmount: '$900,000',
    },
    currency: bscTokens.self,
    token: bscTokens.wmx,
    campaignId: '511800000',
    articleUrl:
      'https://pancakeswap.finance/voting/proposal/0x8bef379b1f3e1f42e180d877edd3ef5b11476300f9de37196904ca4c2070b470',
    tokenOfferingPrice: 0.3,
    version: 3.2,
    twitterUrl: 'https://twitter.com/WombexFinance',
    description: 'Wombex is a customized fork of Convex, designed specifically for the Wombat Exchange ecosystem',
    vestingTitle: 'Earn $WMX by staking assets; Lock $WMX for Fee-Sharing and Wombat Governance',
  },
  {
    id: 'krs',
    cIFO: true,
    address: '0x4CCB9960dEF2f9a55D1a0454a134a8Adb0047B8e',
    isActive: false,
    name: 'KRS',
    poolBasic: {
      raiseAmount: '$420,000',
    },
    poolUnlimited: {
      raiseAmount: '$630,000',
    },
    currency: bscTokens.self,
    token: bscTokens.krs,
    campaignId: '511700000',
    articleUrl:
      'https://pancakeswap.finance/voting/proposal/bafkreicqqvgqpwtnhmlfdwz3ya3hnjm6nvgqbf3fpi4vbxmvqyv6yi3enu',
    tokenOfferingPrice: 0.035,
    version: 3.2,
    twitterUrl: 'https://twitter.com/KingdomRaids',
    description: 'Kingdom Raids is a blockchain-based strategy role-playing game (RPG) game with a NFT metaverse',
    vestingTitle: '$KRS - utility token for Kingdom Raids, a role-playing game (RPG) game.',
  },
  {
    id: 'co',
    address: '0x8baeee7d68cb332c63b3e4a8740072121070a2df',
    isActive: false,
    name: 'CO',
    poolBasic: {
      raiseAmount: '$367,500',
    },
    poolUnlimited: {
      raiseAmount: '$1,102,500',
    },
    currency: bscTokens.self,
    token: bscTokens.co,
    campaignId: '511600000',
    articleUrl:
      'https://pancakeswap.finance/voting/proposal/bafkreiddual5o7vzfcwuazw3lgckis66hka4xytqkttqg2wjtea5pywohy',
    tokenOfferingPrice: 0.035,
    version: 3.2,
    twitterUrl: 'https://twitter.com/Coritecom',
    description:
      'Corite is a blockchain-based music platform jointly powered by fans and artists to finance and promote music in a unique Engage-to-Earn (E2E) model.',
    vestingTitle: '$CO - utility token for the Corite Experience!',
  },
]

export default ifos