import {
  Box,
  Flex,
  Message,
  Tag,
  LockIcon,
  MessageText,
  useTooltip,
  TooltipText,
  Skeleton,
  Text,
  NextLinkFromReactRouter,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { VaultPosition } from 'utils/selfPool'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import useSELFBenefits from './hooks/useSELFBenefits'

const SELFBenefitsCardWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
  padding: 1px 1px 3px 1px;
  background: linear-gradient(180deg, #53dee9, #7645d9);
  border-radius: ${({ theme }) => theme.radii.default};
`

const SELFBenefitsCardInner = styled(Box)`
  position: relative;
  z-index: 1;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: ${({ theme }) => theme.radii.default};

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${({ theme }) => theme.radii.default};
    background: ${({ theme }) => theme.colors.gradientBubblegum};
  }
`

interface SELFBenefitsCardProps {
  onDismiss: () => void
}

const SELFBenefitsCard: React.FC<React.PropsWithChildren<SELFBenefitsCardProps>> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { data: selfBenefits, status: selfBenefitsFetchStatus } = useSELFBenefits()
  const { isMobile } = useMatchBreakpoints()

  const {
    targetRef: selfTargetRef,
    tooltip: selfTooltip,
    tooltipVisible: selfTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`%lockedSELF% SELF (including rewards) are locked in the SELF Pool until %lockedEndTime%`, {
          lockedSELF: selfBenefits?.lockedSELF,
          lockedEndTime: selfBenefits?.lockedEndTime,
        })}
      </Text>
      <NextLinkFromReactRouter to="/pools" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: iSELFTargetRef,
    tooltip: iSELFTooltip,
    tooltipVisible: iSELFTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`iSELF allows you to participate in the IFO public sales and commit up to %iSELF% amount of SELF.`, {
          iSELF: selfBenefits?.iSELF,
        })}
      </Text>
      <NextLinkFromReactRouter to="/ifo" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: bSELFTargetRef,
    tooltip: bSELFTooltip,
    tooltipVisible: bSELFTooltipVisible,
  } = useTooltip(
    <>
      <Text>{t(`bSELF allows you to boost your yield in SMBSwap Farms by up to 2x.`)}</Text>
      <NextLinkFromReactRouter to="/farms" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: vSELFTargetRef,
    tooltip: vSELFTooltip,
    tooltipVisible: vSELFTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`vSELF boosts your voting power to %totalScore% in the SMBSwap voting governance.`, {
          totalScore: selfBenefits?.vSELF?.totalScore,
        })}
      </Text>
      <NextLinkFromReactRouter to="/voting" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  return selfBenefitsFetchStatus === FetchStatus.Fetched ? (
    <>
      {[VaultPosition.None, VaultPosition.Flexible].includes(selfBenefits?.lockPosition) ? (
        <>
          <Flex flexDirection="row" alignItems="center">
            <Tag variant="secondary" mr="auto">
              <Flex alignItems="center">
                <Box as={LockIcon} mr="4px" />
                {t('No SELF locked')}
              </Flex>
            </Tag>
            <Text fontSize="16px">{selfBenefits?.lockedSELF}</Text>
          </Flex>
          <Message mt="8px" mb="16px" variant="warning">
            <MessageText maxWidth="200px">
              {t(
                'Lock SELF to enjoy the benefits of farm yield boosting, participating in IFOs, voting power boosts, and so much more!',
              )}{' '}
              <NextLinkFromReactRouter
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                to="/pools"
                onClick={onDismiss}
              >
                {t('Go to Pools')}
              </NextLinkFromReactRouter>
            </MessageText>
          </Message>
        </>
      ) : [VaultPosition.LockedEnd, VaultPosition.AfterBurning].includes(selfBenefits?.lockPosition) ? (
        <>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <Tag variant="failure" mr="auto">
              <Flex alignItems="center">
                <Box as={LockIcon} mr="4px" />
                {t('SELF staking expired')}
              </Flex>
            </Tag>
            <Text fontSize="16px">{selfBenefits?.lockedSELF}</Text>
          </Flex>
          <Message mt="8px" mb="16px" variant="warning">
            <MessageText maxWidth="200px">
              {t(
                'Renew your staking position to continue enjoying the benefits of farm yield boosting, participating in IFOs, voting power boosts, and so much more!',
              )}{' '}
              <NextLinkFromReactRouter
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                to="/pools"
                onClick={onDismiss}
              >
                {t('Go to Pools')}
              </NextLinkFromReactRouter>
            </MessageText>
          </Message>
        </>
      ) : (
        <SELFBenefitsCardWrapper>
          <SELFBenefitsCardInner>
            <Flex flexDirection="row" alignItems="center">
              <Tag variant="secondary" mr="auto">
                <Flex alignItems="center">
                  <Box as={LockIcon} mr="4px" />
                  {t('SELF locked')}
                </Flex>
              </Tag>
              <TooltipText ref={selfTargetRef} bold fontSize="16px">
                {selfBenefits?.lockedSELF}
              </TooltipText>
              {selfTooltipVisible && selfTooltip}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={iSELFTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                iSELF
              </TooltipText>
              {iSELFTooltipVisible && iSELFTooltip}
              {selfBenefits?.iSELF}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={bSELFTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                bSELF
              </TooltipText>
              {bSELFTooltipVisible && bSELFTooltip}
              {t('Up to %boostMultiplier%x', { boostMultiplier: 2 })}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={vSELFTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                vSELF
              </TooltipText>
              {vSELFTooltipVisible && vSELFTooltip}
              {selfBenefits?.vSELF?.vaultScore}
            </Flex>
          </SELFBenefitsCardInner>
        </SELFBenefitsCardWrapper>
      )}
    </>
  ) : (
    <Skeleton width="100%" height={146} borderRadius="16px" marginBottom={24} />
  )
}

export default SELFBenefitsCard