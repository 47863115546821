import { bscTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.self,
    contractAddress: '0x3d03d12F95Bdc4509804f9Bcee4139b7789DC516',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2.4969',
    isFinished: false,
    isReflection: false,
  },
  {
    sousId: 40,
    stakingToken: bscTokens.cuby,
    earningToken: bscTokens.btcb,
    contractAddress: '0x1bB06ac49b4F09314CC72eDf9B375eeF8A141212',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.000000006097560975609756',
    isFinished: false,
    isReflection: false,
  },  
  {
    sousId: 38,
    stakingToken: bscTokens.win,
    earningToken: bscTokens.rkl,
    contractAddress: '0x8f6Fe458b804972B9A461d8d7878178575eD2aC6',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.0396825396825397',
    isFinished: false,
    isReflection: false,
  },
  {
    sousId: 35,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.x,
    contractAddress: '0x5ABb54C91Cb41DF3198D2B2e9eC91858F27a639E',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '250000',
    isFinished: false,
    isReflection: true,
  },  
  {
    sousId: 34,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.toad,
    contractAddress: '0xc45132AE7b3a1AB8e121137610C6740Db4BD67f1',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '1149.425287356322',
    isFinished: false,
    isReflection: true,
  },
  {
    sousId: 32,
    stakingToken: bscTokens.brnkc,
    earningToken: bscTokens.self,
    contractAddress: '0xbc12bc70De9Ec46c5085e7c7f2eac62d76C0BD8b',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.835707502374169000',
    isFinished: false,
    isReflection: false,
  },  
  {
    sousId: 24,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.tls,
    contractAddress: '0x16e4C8a2e7b6B2b03E86193632F8d92D9Dae20fB',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.0571428571428571',
    isFinished: false,
    isReflection: true,
  },
  {
    sousId: 19,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.crocfi,
    contractAddress: '0x746D5514A6B5a99E07F5D36Ab172B3ABE1e111c9',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.048030739673391000',
    isFinished: false,
    isReflection: false,
  },
  {
    sousId: 18,
    stakingToken: bscTokens.crocfi,
    earningToken: bscTokens.crocfi,
    contractAddress: '0x6fd294Bfc86BA26A7e0E44737f31b1C017bD542D',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.048030739673391000',
    isFinished: false,
    isReflection: false,
  },  
].map((p) => ({
  ...p,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [
  {
    sousId: 39,
    stakingToken: bscTokens.cuby,
    earningToken: bscTokens.btcb,
    contractAddress: '0xB1847465E03064e9949c79889b327823DD745b71',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.00000000563414634',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 37,
    stakingToken: bscTokens.cuby,
    earningToken: bscTokens.wbnb,
    contractAddress: '0xf1F27a9BF4B2BbE519f08eDa1747ea9613B697Ed',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.000001120071684587',
    isFinished: true,
    isReflection: false,
  },  
  {
    sousId: 36,
    stakingToken: bscTokens.cuby,
    earningToken: bscTokens.wbnb,
    contractAddress: '0x1b3f66C9D6E463cfd3204C9aC86dE62766436162',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: false,
    tokenPerBlock: '0.000001120071684587814000',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 33,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.toad,
    contractAddress: '0xB29880dF1EfdEB2e83D3cc5FD5E655548fF641a2',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.3824357681743907',
    isFinished: true,
    isReflection: true,
  },    
  {
    sousId: 31,
    stakingToken: bscTokens.wblk,
    earningToken: bscTokens.wblk,
    contractAddress: '0x609ca786d3b2bbB221150309c432ab89F088D240',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '0.0042342680329994',
    isReflection: false,
    version: 3,
  },  
  {
    sousId: 30,
    stakingToken: bscTokens.wsafe,
    earningToken: bscTokens.wsafe,
    contractAddress: '0x8Cb95C62E97eD2F7345bba47EA573C042a7B2904',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '0.022222222',
    isReflection: true,
    version: 3,
  },  
  {
    sousId: 29,
    stakingToken: bscTokens.btce,
    earningToken: bscTokens.usde,
    contractAddress: '0xda3aAb87e7FF67229EBd2F24a1E76890DAbE5306',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '23.180343069077420000',
    sortOrder: 1,
    isFinished: true,
    isReflection: true,
    version: 3,
  },  
  {
    sousId: 28,
    stakingToken: bscTokens.rants,
    earningToken: bscTokens.rants,
    contractAddress: '0x52B98d11A81b03C71C9Cd3891087d686fA871D1a',
    poolCategory: PoolCategory.COMMUNITY,
    tokenPerBlock: '166.6666666666667',
    isReflection: false,
    version: 3,
  },
  {
    sousId: 27,
    stakingToken: bscTokens.rmb,
    earningToken: bscTokens.rmb,
    contractAddress: '0x5efD668C9Bc9D9262e5886d2C8589DAC07BCD520',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '1.209677419354839',
    sortOrder: 1,
    isFinished: false,
    isReflection: true,
  },
  {
    sousId: 26,
    stakingToken: bscTokens.tater,
    earningToken: bscTokens.tater,
    contractAddress: '0x775102A8c9d5756ed8c91a65818f9E969c2d76cA',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.3435114503816794',
    sortOrder: 1,
    isFinished: false,
    isReflection: false,
  },  
  {
    sousId: 25,
    stakingToken: bscTokens.usde,
    earningToken: bscTokens.wbnb,
    contractAddress: '0x4975D14587B6704d13418dD0AB29F43f65482903',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.000002500000000000',
    isFinished: true,
    isReflection: true,
  },  
  {
    sousId: 23,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.wpgo,
    contractAddress: '0xeCCb904fdF57880196a3E796808b65E354A08466',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.283446712018140600',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 22,
    stakingToken: bscTokens.wblk,
    earningToken: bscTokens.wblk,
    contractAddress: '0xd308baE177e918F69fffE1d1b9eA0BffB0Dd5bc0',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.001367016622922100',
    isFinished: false,
    isReflection: false,
  },  
  {
    sousId: 21,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.tater,
    contractAddress: '0xB203705bDe6BcF61cfD24afCE344D240CEd4AE54',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.357142857142857100',
    sortOrder: 1,
    isFinished: true,
    isReflection: false,
  },  
  {
    sousId: 20,
    stakingToken: bscTokens.tater,
    earningToken: bscTokens.tater,
    contractAddress: '0x71F48dE4Ae8F0CEF11d50ff47d322A514bD8c8Ee',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.357142857142857100',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 17,
    stakingToken: bscTokens.btr3b,
    earningToken: bscTokens.btr3b,
    contractAddress: '0x9ccCE114bC7624A35023D49446079a3057e0D90f',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.01111111',
    sortOrder: 1,
    isFinished: true,
    isReflection: true,
  },  
  {
    sousId: 16,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.b1mt,
    contractAddress: '0x1a0F5546063f264914fEABa844aCC711F6433CF8',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.005',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 15,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.rants,
    contractAddress: '0x23715ACe2bA4bD4f965dbEA657f67DCfDd3fCeF6',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '500',
    isFinished: true,
    isReflection: false,
  },  
  {
    sousId: 14,
    stakingToken: bscTokens.btce,
    earningToken: bscTokens.b1mt,
    contractAddress: '0x9cd3c97EfD57ba4508fEa14AC91DB3a5707f9c92',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.01',
    isFinished: true,
    isReflection: true,
  },
  {
    sousId: 13,
    stakingToken: bscTokens.smbelon,
    earningToken: bscTokens.smbr,
    contractAddress: '0x4b2da4cC11F5b37773d23e9f1f04CD4b0Cd0De96',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.2',
    isFinished: true,
    isReflection: true,
  },  
  {
    sousId: 12,
    stakingToken: bscTokens.smbr,
    earningToken: bscTokens.self,
    contractAddress: '0xcB2a4B49D6deCaB1320D33449c5FEC5681de60F8',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02',
    isFinished: true,
    isReflection: true,
  },
  {
  sousId: 11,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smbr,
    contractAddress: '0x3769B1139dE3a115d65CF80E3Aa326c1C5c9ED21',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02',
    isFinished: true,
    isReflection: true,
  },  
  {
    sousId: 10,
    stakingToken: bscTokens.btce,
    earningToken: bscTokens.btcb,
    contractAddress: '0xfE4C33107D681d97Cec6cECE545163a7074a9481',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000000179640719',
    isFinished: true,
    isReflection: true,
  },
  {
    sousId: 9,
    stakingToken: bscTokens.btce,
    earningToken: bscTokens.self,
    contractAddress: '0x2Ab7AEAFc8122A750fe3C497e23e76981A255e5E',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.089',
    isFinished: true,
    isReflection: true,
  },
  {
    sousId: 8,
    stakingToken: bscTokens.smbelon,
    earningToken: bscTokens.self,
    contractAddress: '0xc054c38F698003CaeE6FE49E179365198719f0E9',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 7,
    stakingToken: bscTokens.rants,
    earningToken: bscTokens.rants,
    contractAddress: '0xB71ee561EF8dcE514d3780A8824c714bFc44843E',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '655',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 6,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smb,
    contractAddress: '0x8513E1554CdDb60E641FADeA8c448A69529BEC94',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '49.75',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 5,
    stakingToken: bscTokens.smbelon,
    earningToken: bscTokens.self,
    contractAddress: '0x66FB9DEC3358Dde0387B735839C03A95E2D352e7',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 4,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smbelon,
    contractAddress: '0xA61e717Eae363658fAa393B62188704D42A2154b',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2155172',
    isFinished: true,
    isReflection: false,
  },  
  {
    sousId: 3,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smb,
    contractAddress: '0x05AB57759973CD42950296C7200cF067Ae5F2E00',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '263',
    isFinished: true,
    isReflection: false,
  },
  {
    sousId: 2,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smbge,
    contractAddress: '0x1b4357804dCD2F6D566a0DF2E152a899Ed1652A3',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '167142',
    isFinished: false,
    isReflection: false,
  },
  {
    sousId: 1,
    stakingToken: bscTokens.self,
    earningToken: bscTokens.smbelon,
    contractAddress: '0x905CA5D9e1A182B06Ef3A5a6A96dda50F52D303d',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '116117',
    isFinished: true,
    isReflection: false,
  },  
  // {
  //   sousId: 248,
  //   stakingToken: bscTokens.self,
  //   earningToken: bscTokens.ccar,
  //   contractAddress: {
  //     97: '',
  //     56: '0x9e31aef040941E67356519f44bcA07c5f82215e5',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.6093',
  // },
].map((p) => ({
  ...p,
  isFinished: true,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
