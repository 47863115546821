import { bscTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../../types'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    token: bscTokens.sd,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'WBNB-SD LP',
    lpAddress: '0xfD05C106E336bc5696686F422A35EE7a136E1C89',
  },
  {
    pid: null,
    token: bscTokens.sis,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'WBNB-SIS LP',
    lpAddress: '0xbCA9057666872B7b7CfC9718E68C96c64d69E1Ad',
  },
  {
    pid: null,
    token: bscTokens.xcad,
    quoteToken: bscTokens.busd,
    lpSymbol: 'XCAD-BUSD LP',
    lpAddress: '0x43d86605F8d22407b959D668B2689eafba23571B',
  },
  {
    pid: null,
    token: bscTokens.toad,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'TOAD-WBNB LP',
    lpAddress: '0x89d9cb973ec4fe5ff685972c7867fb10c7fa3400',
  },
  {
    pid: null,
    token: bscTokens.x,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'X-WBNB LP',
    lpAddress: '0xcc160b018bc634ed1585115d6178656a9912f2ec',
  },
  {
    pid: null,
    token: bscTokens.cuby,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'CUBY-WBNB LP',
    lpAddress: '0xadddf7329534e4502f5350c80790640774555963',
  },
  {
    pid: null,
    token: bscTokens.rkl,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'RKL-WBNB LP',
    lpAddress: '0x9e49c98bd667a8b06fff26ddfcbb9e5dbc0ecd60',
  },
  {
    pid: null,
    token: bscTokens.win,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'WIN-WBNB LP',
    lpAddress: '0xc222b7142200bcfe8754ac34da2288271cad2e19',
  },
  {
    pid: null,
    token: bscTokens.btcb,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'BTCB-WBNB LP',
    lpAddress: '0x26a48043511446b260882ef489f4fa1b5226d2fe',
  },
  {
    pid: null,
    token: bscTokens.brnkc,
    quoteToken: bscTokens.wbnb,
    lpSymbol: 'BRNKC-WBNB LP',
    lpAddress: '0x8ac9f2f9159ae7906e29ecd07c80647d03e6ada6',
  },  
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default priceHelperLps
