import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | SMBSwap',
  defaultTitle: 'SMBSwap',
  description:
    'Discover SMBSwap, the fastest growing DEX on BNB Smart Chain (BSC) with the best SELF farms and Made pools in DeFi.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SMBSwap',
    site: '@SMBSwap',
  },
  openGraph: {
    title: 'SMBSwap - DeFi exchange for the Self-Made Billionaire on BNB Smart Chain (BSC)',
    description:
      'The hottest new AMM on BSC! Earn SELF through yield farming, then stake it in Made Pools to earn more tokens. Swap, stake, and earn — all on a platform you can trust.',
    images: [{ url: 'https://smbswap.finance/images/hero.png' }],
  },
}
