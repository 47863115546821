import { ethereumTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 1,
  //   lpSymbol: 'USDC-ETH LP',
  //   lpAddress: '0x1ac1A8FEaAEa1900C4166dEeed0C11cC10669D36',
  // token0: ethereumTokens.usdc,
  // token1: ethereumTokens.weth,
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'ETH-USDT LP',
  //   lpAddress: '0x6CA298D2983aB03Aa1dA7679389D955A4eFEE15C',
  //   token0: ethereumTokens.weth,
  //   token1: ethereumTokens.usdt,
  //   feeAmount: FeeAmount.LOW,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'USDC-USDT LP',
  //   lpAddress: '0x04c8577958CcC170EB3d2CCa76F9d51bc6E42D8f',
  //   token0: ethereumTokens.usdc,
  //   token1: ethereumTokens.usdt,
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'WBTC-ETH LP',
  //   lpAddress: '0x9b5699D18DFF51fc65fB8ad6F70d93287C36349f',
  //   token0: ethereumTokens.wbtc,
  //   token1: ethereumTokens.weth,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'CAKE-ETH LP',
  //   lpAddress: '0x517F451b0A9E1b87Dc0Ae98A05Ee033C3310F046',
  //   token0: ethereumTokens.cake,
  //   token1: ethereumTokens.weth,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'CAKE-USDC LP',
  //   lpAddress: '0x11A6713B702817DB0Aa0964D1AfEe4E641319732',
  //   token0: ethereumTokens.cake,
  //   token1: ethereumTokens.usdc,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 7,
  //   lpSymbol: 'DAI-USDC LP',
  //   lpAddress: '0xD9e497BD8f491fE163b42A62c296FB54CaEA74B7',
  //   token0: ethereumTokens.dai,
  //   token1: ethereumTokens.usdc,
  //   feeAmount: FeeAmount.LOWEST,
  // },
])

const farms: SerializedFarmConfig[] = [
  // {
  //   pid: 124,
  //   vaultPid: 1,
  //   lpSymbol: 'ETH-USDC LP',
  //   lpAddress: '0x2E8135bE71230c6B1B4045696d41C09Db0414226',
  //   token1: ethereumTokens.weth,
  //   token0: ethereumTokens.usdc,
  // },
  // {
  //   pid: 125,
  //   vaultPid: 2,
  //   lpSymbol: 'ETH-USDT LP',
  //   lpAddress: '0x17C1Ae82D99379240059940093762c5e4539aba5',
  //   token1: ethereumTokens.weth,
  //   token0: ethereumTokens.usdt,
  // },
  // {
  //   pid: 126,
  //   vaultPid: 3,
  //   lpSymbol: 'WBTC-ETH LP',
  //   lpAddress: '0x4AB6702B3Ed3877e9b1f203f90cbEF13d663B0e8',
  //   token1: ethereumTokens.weth,
  //   token0: ethereumTokens.wbtc,
  // },
  // {
  //   pid: 141,
  //   vaultPid: 4,
  //   lpSymbol: 'SDAO-WETH LP',
  //   lpAddress: '0xDA7cF6a0CD5d5e8D10AB55d8bA58257813a239cA',
  //   token1: ethereumTokens.weth,
  //   token0: ethereumTokens.sdao,
  // },  
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))


export default farms
