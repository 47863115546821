export const bSelfFarmBoosterV3ABI = [
    {
      inputs: [
        { internalType: 'address', name: '_SELF', type: 'address' },
        { internalType: 'address', name: '_SELFPool', type: 'address' },
        { internalType: 'contract IMasterChefV3', name: '_v3', type: 'address' },
        { internalType: 'uint256', name: '_max', type: 'uint256' },
        { internalType: 'uint256', name: '_cA', type: 'uint256' },
        { internalType: 'uint256', name: '_cB', type: 'uint256' },
      ],
      stateMutability: 'nonpayable',
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'ActiveFarmPool',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'bool', name: 'down', type: 'bool' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'AdjustTotalLockedAmount',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      ],
      name: 'DeactiveFarmPool',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
        { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'uint256', name: 'pid', type: 'uint256' },
        { indexed: false, internalType: 'bool', name: 'status', type: 'bool' },
      ],
      name: 'UpdateBoostFarms',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'uint256', name: 'oldCA', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'newCA', type: 'uint256' },
      ],
      name: 'UpdateCA',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'uint256', name: 'oldCB', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'newCB', type: 'uint256' },
      ],
      name: 'UpdateCB',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'lockedAmount', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'lockedDuration', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'totalLockedAmount', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'maxLockDuration', type: 'uint256' },
      ],
      name: 'UpdateSELFPool',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: 'uint256', name: 'max', type: 'uint256' }],
      name: 'UpdateMaxBoostPosition',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'user', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'pid', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'oldMultiplier', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'newMultiplier', type: 'uint256' },
      ],
      name: 'UpdatePoolBoostMultiplier',
      type: 'event',
    },
    {
      inputs: [],
      name: 'BOOST_PRECISION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BOOST_RATIO_PRECISION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'BOOST_WEIGHT_PRECISION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'SELF',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'SELF_POOL',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'CA_PRECISION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MASTER_CHEF_V3',
      outputs: [{ internalType: 'contract IMasterChefV3', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BOOST_POSITION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_BOOST_PRECISION',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_CA',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MAX_CB',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_CA',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MIN_CB',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'activate',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
      name: 'activedPositions',
      outputs: [{ internalType: 'uint256[]', name: 'positions', type: 'uint256[]' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bool', name: '_down', type: 'bool' },
        { internalType: 'uint256', name: '_amount', type: 'uint256' },
      ],
      name: 'adjustTotalLockedAmount',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'avgLockDuration',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'cA',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'cB',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'deactive',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'everBoosted',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'getUserMultiplier',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'isBoostedPool',
      outputs: [
        { internalType: 'bool', name: '', type: 'bool' },
        { internalType: 'uint256', name: '', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_user', type: 'address' },
        { internalType: 'uint256', name: '_lockedAmount', type: 'uint256' },
        { internalType: 'uint256', name: '_lockedDuration', type: 'uint256' },
        { internalType: 'uint256', name: '_totalLockedAmount', type: 'uint256' },
        { internalType: 'uint256', name: '_maxLockDuration', type: 'uint256' },
      ],
      name: 'onSELFPoolUpdate',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: '_user', type: 'address' },
        { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
      ],
      name: 'removeBoostMultiplier',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [
        { internalType: 'uint256', name: '_pid', type: 'uint256' },
        { internalType: 'bool', name: '_status', type: 'bool' },
      ],
      name: 'setBoosterFarms',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_cA', type: 'uint256' }],
      name: 'setCA',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_cB', type: 'uint256' }],
      name: 'setCB',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_max', type: 'uint256' }],
      name: 'setMaxBoostPosition',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'totalLockedAmount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
      name: 'updatePositionBoostMultiplier',
      outputs: [{ internalType: 'uint256', name: '_multiplier', type: 'uint256' }],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'userInfo',
      outputs: [{ internalType: 'uint256', name: 'size', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'userLockedInfos',
      outputs: [
        { internalType: 'bool', name: 'init', type: 'bool' },
        { internalType: 'uint256', name: 'lockedAmount', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'whiteList',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
  ] as const