export const PROPOSALS_TO_SHOW = 10
export const ADMINS = [
  '0x26e82CB17cfd4ef12096f38f3ba0DAD6ea5B5035', // CREK
  '0x718aC3CC4CcCD7f473C36cf4472F450adE27D926', // PEACH
  '0xa8952d4243C935Fd1D253f0d6Bc67C882447E4A5', // GODFATHER
].map((address) => address.toLowerCase())
export const IPFS_GATEWAY = 'https://gateway.ipfs.io/ipfs'
export const SNAPSHOT_VERSION = '0.1.3'
export const SMB_SPACE = 'smbswap.eth'
export const VOTE_THRESHOLD = 75000
