import { ChainId } from '@pancakeswap/sdk'

export const GRAPH_API_PROFILE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/profile'
export const GRAPH_API_PREDICTION_BNB = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-v2'
export const GRAPH_API_PREDICTION_CAKE = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction-cake'

export const GRAPH_API_LOTTERY_OLD = 'https://api.thegraph.com/subgraphs/name/cr3k/lottery'
export const GRAPH_API_LOTTERY = 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/9UsuupUqNttPEXVnvEQzAcawG5YCtDSpjb7Vx46JPkU3'
export const GRAPH_API_BUSDLOTTERY = 'https://api.thegraph.com/subgraphs/name/cr3k/busd-lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://profile.pancakeswap.com'
export const API_NFT = 'https://nft.smbswap.finance/api/v1'
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/pancakeswap/pottery'
export const ONRAMP_API_BASE_URL = 'https://pcs-onramp-api.com'
export const MOONPAY_BASE_URL = 'https://api.moonpay.com'
export const MOONPAY_SIGN_URL = 'https://pcs-on-ramp-api.com'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/9BtGwsWynjj21VyrAtNfeKG5kMhcZ7Z12T53wo7PBTLj'
export const INFO_NR_CLIENT = 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/9BtGwsWynjj21VyrAtNfeKG5kMhcZ7Z12T53wo7PBTLj' // 'https://proxy-worker-dev.pancake-swap.workers.dev/bsc-exchange'
export const INFO_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-eth'
export const INFO_CLIENT_POLYGON = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-polygon'
export const INFO_CLIENT_BRNKC = 'https://api.thegraph.com/subgraphs/name/pancakeswap/exhange-brnkc'
export const BLOCKS_CLIENT = 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/BW2Ds6WyQo3zn2daurikYJk9P73G4bkEZdnSUNnY2m6s'
export const BLOCKS_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const BLOCKS_CLIENT_POLYGON_ZKEVM = 'https://api.studio.thegraph.com/query/45376/polygon-zkevm-block/version/latest'
export const BLOCKS_CLIENT_ZKSYNC = 'https://api.studio.thegraph.com/query/45376/blocks-zksync/version/latest' // TODO: Crek ours
export const BLOCKS_CLIENT_POLYGON = 'https://api.thegraph.com/subgraphs/name/matthewlilley/polygon-blocks'
export const BLOCKS_CLIENT_BRNKC = 'https://api.thegraph.com/subgraphs/name/cr3k/brnkc-blocks' // TODO: Crek ours
export const STABLESWAP_SUBGRAPH_CLIENT = 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/8o2ZdXbsnHapQvT9Jh8NXLivnLSYVGQXsgVfBzfckLiW'
export const GRAPH_API_NFTMARKET = 'https://api.thegraph.com/subgraphs/name/pancakeswap/nft-market'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v4'

export const BIT_QUERY = 'https://graphql.bitquery.io'

export const ACCESS_RISK_API = '/api/risk'

export const CELER_API = 'https://api.celerscan.com/scan'

export const INFO_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: INFO_CLIENT,
  [ChainId.ETHEREUM]: INFO_CLIENT_ETH,
  [ChainId.POLYGON_ZKEVM]: 'https://api.studio.thegraph.com/query/45376/exchange-v2-polygon-zkevm/version/latest',
  [ChainId.ZKSYNC_TESTNET]: 'https://api.studio.thegraph.com/query/45376/exchange-v2-zksync-testnet/version/latest',  
  [ChainId.ZKSYNC]: ' https://api.studio.thegraph.com/query/45376/exchange-v2-zksync/version/latest', // TODO: Crek ours 
  [ChainId.LINEA_TESTNET]: 'https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exhange-eth/',
  [ChainId.ARBITRUM_ONE]: 'https://thegraph.com/hosted-service/subgraph/chef-jojo/exchange-v2-arb',
  [ChainId.BRNKC]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-brnkc',  // TODO: Crek ours 
}

export const BLOCKS_CLIENT_WITH_CHAIN = {
  [ChainId.BSC]: BLOCKS_CLIENT,
  [ChainId.ETHEREUM]: BLOCKS_CLIENT_ETH,
  [ChainId.POLYGON_ZKEVM]: BLOCKS_CLIENT_POLYGON_ZKEVM,
  [ChainId.BRNKC]: BLOCKS_CLIENT_BRNKC,
  [ChainId.ZKSYNC]: BLOCKS_CLIENT_ZKSYNC,
}

export const ASSET_CDN = 'https://smbswap.finance'

export const V3_SUBGRAPH_URLS = {
  [ChainId.ETHEREUM]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-v3-eth',
  [ChainId.GOERLI]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-v3-goerli',
  [ChainId.BSC]: 'https://gateway-arbitrum.network.thegraph.com/api/0ad1a0a57a31ad043140fbc99e2b60a0/subgraphs/id/8XiGZs3G3dDL3YQJx7CsMGXdn3CUBBC9CVpCe1xrsSA7',
  [ChainId.BSC_TESTNET]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-v3-chapel',
  // TODO: new chains
  [ChainId.ARBITRUM_ONE]: 'https://thegraph.com/hosted-service/subgraph/chef-jojo/exchange-v3-arb',
  [ChainId.ARBITRUM_GOERLI]: 'https://api.thegraph.com/subgraphs/name/chef-jojo/exhange-v3-arb-goerli',
  [ChainId.POLYGON_ZKEVM]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-polygon-zkevm/v0.0.0',
  [ChainId.POLYGON_ZKEVM_TESTNET]: null,
  [ChainId.ZKSYNC]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-zksync/version/latest',
  [ChainId.ZKSYNC_TESTNET]: 'https://api.studio.thegraph.com/query/45376/exchange-v3-zksync-testnet/version/latest',
  [ChainId.LINEA_TESTNET]:
    'https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exchange-v3-linea-goerli',
  [ChainId.BRNKC]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-v3-brnkc',
  [ChainId.BRNKC_TESTNET]: 'https://api.thegraph.com/subgraphs/name/cr3k/exchange-v3-tbrnkc',    
} satisfies Record<ChainId, string | null>

export const TRADING_REWARD_API = 'https://smb-trading-fee-rebate-api.smbswap.com/api/v1'

export const QUOTING_API = `${process.env.NEXT_PUBLIC_QUOTING_API}/v0/quote`

export const FARMS_API = 'https://farms-dev.info1613.workers.dev/'

export const MERCURYO_WIDGET_ID = process.env.NEXT_PUBLIC_MERCURYO_WIDGET_ID || '76ba4ff5-2686-4ed4-8666-fadb0d9a5888'