import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_FARMS = [
    ChainId.BSC,
    ChainId.BSC_TESTNET,
    ChainId.ETHEREUM,
    ChainId.GOERLI,
    ChainId.ZKSYNC_TESTNET,
    ChainId.POLYGON_ZKEVM_TESTNET,
    ChainId.POLYGON_ZKEVM,
    ChainId.BRNKC,
  ]

export const SUPPORT_ZAP = [ChainId.BSC, ChainId.BSC_TESTNET]
