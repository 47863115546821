import useSWRImmutable from 'swr/immutable'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from './bigNumber'

export const useSelfPrice = () => {
  return useSWRImmutable(
    ['self-usd-price'],
    async () => {
      const response = await fetch('https://api.dexscreener.io/latest/dex/tokens/0x7a364484303B38Bce7B0ab60a20DA8F2F4370129');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const self = await response.json();

      const priceUsd = self.pairs.find(pair => pair.priceUsd !== undefined);

      return priceUsd.priceUsd as string    
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}

// for migration to bignumber.js to avoid breaking changes
export const useSelfPriceAsBN = () => {
  const { data } = useSWRImmutable(
    ['self-usd-price-bn'],
    async () => {
      const response = await fetch('https://api.dexscreener.io/latest/dex/tokens/0x7a364484303B38Bce7B0ab60a20DA8F2F4370129');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const self = await response.json();

      const priceUsd = self.pairs.find(pair => pair.priceUsd !== undefined);

      return new BigNumber(priceUsd.priceUsd)
    },
    {
      compare: (a, b) => {
        if (!a && !b) return true
        if (!a || !b) return false
        return a.eq(b)
      },
      refreshInterval: 1_000 * 10,
    },
  )

  return data ?? BIG_ZERO
}